import produce from "immer";

import { getCalendarDetail } from "../actions/calendars";

const initialState = {
  calendarDetail: {
    id: "249d38e2-0183-4598-90e4-f4d65fedea93",
    start_date: "20200102",
    end_date: "20201231",
  },
  loading: false,
};

const calendars = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case getCalendarDetail.REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case getCalendarDetail.SUCCESS: {
        const stateDraft = draft;
        stateDraft.calendarDetail = action.payload;
        stateDraft.loading = false;
        break;
      }
      case getCalendarDetail.FAILURE: {
        return {
          ...state,
          loading: false,
        };
      }
      default:
        return state;
    }
  });

export default calendars;
