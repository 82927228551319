import React from "react";

import styles from "./error.scss";

const ErrorView = () => {
  return (
    <div className="page">
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>
          <h1>Sorry, something went wrong 💩</h1>
        </div>
      </div>
    </div>
  );
};

export default ErrorView;
