import produce from "immer";
import dayjs from "dayjs";

import { getDays, CREATE_DUMMY_DAYS, CREATE_DAYS } from "../actions/days";

const data = {
  count: 114,
  next: null,
  previous: null,
  results: [
    {
      day: "20201123",
      entries: [
        {
          id: 148,
          title: "Toter Falter auf Asphalt - ohne Brille",
          images: [
            {
              image:
                "/photos/148/2d5db71c-d1c9-4b8e-8acd-58ce9b0d2ab2.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/148/2d5db71c-d1c9-4b8e-8acd-58ce9b0d2ab2.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201011",
      entries: [
        {
          id: 128,
          title: "Gabor macht Rückwärtssalto",
          images: [
            {
              image:
                "/photos/128/55153228-1248-4f75-b49d-b29087d54f88.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/128/55153228-1248-4f75-b49d-b29087d54f88.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200814",
      entries: [
        {
          id: 117,
          title: "Flecken auf Kissen",
          images: [
            {
              image:
                "/photos/117/781dfe87-d96a-4d6f-b730-3fbbeb4204b1.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/117/781dfe87-d96a-4d6f-b730-3fbbeb4204b1.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201113",
      entries: [
        {
          id: 139,
          title: "Ava und Mia haben Muffins gebacken",
          images: [
            {
              image:
                "/photos/139/12cce3d9-d2d8-4c40-a56c-f56387be3c5d.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/139/12cce3d9-d2d8-4c40-a56c-f56387be3c5d.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200314",
      entries: [
        {
          id: 36,
          title: "Anton als Hai",
          images: [
            {
              image:
                "/photos/36/1fa56170-6122-45c7-be0a-d3443c542aa4.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/36/1fa56170-6122-45c7-be0a-d3443c542aa4.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200802",
      entries: [
        {
          id: 29,
          title: "Jacob hat jetzt ein Patenkind in Afrika",
          images: [
            {
              image:
                "/photos/29/578c1be2-fbe6-4aba-95b0-bcefbd496f41.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/29/578c1be2-fbe6-4aba-95b0-bcefbd496f41.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200919",
      entries: [
        {
          id: 114,
          title: "Guerilla Gorilla",
          images: [
            {
              image:
                "/photos/114/8c76bf81-1454-4a1a-bb7f-5d4344acc48e.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/114/8c76bf81-1454-4a1a-bb7f-5d4344acc48e.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201209",
      entries: [
        {
          id: 146,
          title: "Spiegelung auf Ententeich",
          images: [
            {
              image:
                "/photos/146/db32f196-4cb7-48f1-800a-50960e491595.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/146/db32f196-4cb7-48f1-800a-50960e491595.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200717",
      entries: [
        {
          id: 93,
          title: "Straßenflohmarkt",
          images: [
            {
              image:
                "/photos/93/a8e471b5-1e03-493b-8f23-851cc718b6d4.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/93/a8e471b5-1e03-493b-8f23-851cc718b6d4.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201001",
      entries: [
        {
          id: 126,
          title: "Blick in Nachbars Fenster",
          images: [
            {
              image:
                "/photos/126/e652f286-2bb3-461b-ad7b-2ac5b591c643.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/126/e652f286-2bb3-461b-ad7b-2ac5b591c643.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200206",
      entries: [
        {
          id: 13,
          title: "Corona",
          images: [
            {
              image:
                "/photos/13/aedd5815-f5c7-40e8-8cf4-6d736c6c13c6.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/13/aedd5815-f5c7-40e8-8cf4-6d736c6c13c6.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200229",
      entries: [
        {
          id: 49,
          title:
            "Das Boot als Symbol der ironischen Existenz (siehe Matthias Gronemeyer) Konrad hat ein Boot gezeichnet",
          images: [
            {
              image:
                "/photos/49/f6d767c2-5ca1-4262-94e8-69fec42c6975.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/49/f6d767c2-5ca1-4262-94e8-69fec42c6975.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200205",
      entries: [
        {
          id: 5,
          title: "Habe ich dich erschreckt, Mama?",
          images: [
            {
              image:
                "/photos/5/84f5d2ec-c633-426d-b291-4e60be1841cc.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/5/84f5d2ec-c633-426d-b291-4e60be1841cc.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200419",
      entries: [
        {
          id: 85,
          title: "Annes Baumhaus",
          images: [
            {
              image:
                "/photos/85/73eb8628-4f10-406f-b729-3ac7b15836fc.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/85/73eb8628-4f10-406f-b729-3ac7b15836fc.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201213",
      entries: [
        {
          id: 147,
          title: "Stillleben aus Vans und Ballettschuhen",
          images: [
            {
              image:
                "/photos/147/5a63e62d-61b1-4f00-8c4e-f213e67193cf.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/147/5a63e62d-61b1-4f00-8c4e-f213e67193cf.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200520",
      entries: [
        {
          id: 86,
          title:
            "Simon findet einen kleinen Walnussbaum im Sand. Frei nach Louise Bourgeois: Es ist nicht wichtig, ob du eine zarte Pflanze bist. Es ist nur wichtig, dass du es weißt.",
          images: [
            {
              image:
                "/photos/86/b4fe857a-4dfa-41f0-befe-d379653d686e.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/86/b4fe857a-4dfa-41f0-befe-d379653d686e.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200730",
      entries: [
        {
          id: 28,
          title: "Set your mind free and enjoy the moment",
          images: [
            {
              image:
                "/photos/28/7ef5c7c7-f08f-4535-95c4-ae57ef3e5b16.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/28/7ef5c7c7-f08f-4535-95c4-ae57ef3e5b16.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201031",
      entries: [
        {
          id: 150,
          title: "Smashing Pumpkins",
          images: [
            {
              image:
                "/photos/150/83f0ecf5-1998-4df5-b8d3-2ceac5a6f27d.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/150/83f0ecf5-1998-4df5-b8d3-2ceac5a6f27d.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200428",
      entries: [
        {
          id: 64,
          title: "Abstand halten! Bärenseen",
          images: [
            {
              image:
                "/photos/64/754477be-7859-4bf7-9ced-643a7952ddc2.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/64/754477be-7859-4bf7-9ced-643a7952ddc2.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201218",
      entries: [
        {
          id: 149,
          title: "El Fuerte",
          images: [
            {
              image:
                "/photos/149/d62eff71-1eed-4aeb-a2ba-db37118d8394.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/149/d62eff71-1eed-4aeb-a2ba-db37118d8394.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200530",
      entries: [
        {
          id: 75,
          title: "Zecke haut unter Mikroskop ab",
          images: [
            {
              image:
                "/photos/75/a3cd488d-75a4-489e-b41c-b54a42368862.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/75/a3cd488d-75a4-489e-b41c-b54a42368862.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200810",
      entries: [
        {
          id: 104,
          title: "Wehe, sie tragen dich nicht",
          images: [
            {
              image:
                "/photos/104/8a000c1a-0cf4-44ac-9bb3-83d92e45a23a.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/104/8a000c1a-0cf4-44ac-9bb3-83d92e45a23a.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200901",
      entries: [
        {
          id: 107,
          title:
            "Ich weiß nicht, ob ich Lust habe alt zu werden, aber wenn, dann so!",
          images: [
            {
              image:
                "/photos/107/85716154-fa7f-4fa0-a1df-b4f8c8d0ce4a.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/107/85716154-fa7f-4fa0-a1df-b4f8c8d0ce4a.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200305",
      entries: [
        {
          id: 21,
          title: "Antons Schattenspiele",
          images: [
            {
              image:
                "/photos/21/567c4eaa-19ac-4571-a389-9f9886186287.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/21/567c4eaa-19ac-4571-a389-9f9886186287.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200212",
      entries: [
        {
          id: 48,
          title: "Flucht vor dem Feuer",
          images: [
            {
              image:
                "/photos/48/40d01c25-ed84-4c48-afb6-62366205ecf0.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/48/40d01c25-ed84-4c48-afb6-62366205ecf0.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200223",
      entries: [
        {
          id: 32,
          title: "Raus aus Idlib",
          images: [
            {
              image:
                "/photos/32/9858a555-e379-43e8-8afe-9ab438f8896d.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/32/9858a555-e379-43e8-8afe-9ab438f8896d.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200502",
      entries: [
        {
          id: 65,
          title: "Molch an der Glems. Konrad im Glück!",
          images: [
            {
              image:
                "/photos/65/a9720ceb-90ec-4012-8043-36534144743f.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/65/a9720ceb-90ec-4012-8043-36534144743f.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200616",
      entries: [
        {
          id: 44,
          title: "Liebeskummer I - Ana Mendieta Bird Transformation",
          images: [
            {
              image:
                "/photos/44/3b1c81ad-23ae-45c0-a081-1a3937427cb1.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/44/3b1c81ad-23ae-45c0-a081-1a3937427cb1.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200412",
      entries: [
        {
          id: 63,
          title: "Blick in die Baumkronen",
          images: [
            {
              image:
                "/photos/63/1e580b24-825f-4245-b58a-4f9935ed9fba.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/63/1e580b24-825f-4245-b58a-4f9935ed9fba.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200507",
      entries: [
        {
          id: 22,
          title: "Konrad übt für Halloween",
          images: [
            {
              image:
                "/photos/22/f3cac463-ff17-4b01-ad3d-f76cf536a546.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/22/f3cac463-ff17-4b01-ad3d-f76cf536a546.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200628",
      entries: [
        {
          id: 47,
          title: "Immer schön Kopf über Wasser halten und lächeln",
          images: [
            {
              image:
                "/photos/47/9bd8b307-dc1e-4acf-b507-9bfdc447da35.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/47/9bd8b307-dc1e-4acf-b507-9bfdc447da35.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200910",
      entries: [
        {
          id: 113,
          title: "Anke schwimmt in einem Seeparadies",
          images: [
            {
              image:
                "/photos/113/42ea83fb-b414-46c8-8586-8099511b3359.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/113/42ea83fb-b414-46c8-8586-8099511b3359.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201103",
      entries: [
        {
          id: 136,
          title: "Essiggurkenlimette",
          images: [
            {
              image:
                "/photos/136/38ace683-1025-4071-a1e8-524d0cf1cd6e.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/136/38ace683-1025-4071-a1e8-524d0cf1cd6e.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200828",
      entries: [
        {
          id: 103,
          title: "Mama, ist das ein Insekt?",
          images: [
            {
              image:
                "/photos/103/78b958f1-52bf-46b6-9214-70879e618085.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/103/78b958f1-52bf-46b6-9214-70879e618085.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200420",
      entries: [
        {
          id: 60,
          title: "Shutdown - leere Fußgängerkreuzung in Tokio",
          images: [
            {
              image:
                "/photos/60/e3e9d17c-8b08-4644-8e22-b3f2487e5bed.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/60/e3e9d17c-8b08-4644-8e22-b3f2487e5bed.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200629",
      entries: [
        {
          id: 90,
          title: "EKG-Abdruck am Bauch",
          images: [
            {
              image:
                "/photos/90/0972d54c-cafb-46a5-a789-5762f046c089.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/90/0972d54c-cafb-46a5-a789-5762f046c089.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200925",
      entries: [
        {
          id: 119,
          title: "Maiks Bauch",
          images: [
            {
              image:
                "/photos/119/4c9273b0-7ee6-4237-b65e-5135d9d08c94.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/119/4c9273b0-7ee6-4237-b65e-5135d9d08c94.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201109",
      entries: [
        {
          id: 138,
          title: "Braut",
          images: [
            {
              image:
                "/photos/138/3c58c5d3-ac31-4a03-a72e-6c3285f0a8f6.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/138/3c58c5d3-ac31-4a03-a72e-6c3285f0a8f6.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200316",
      entries: [
        {
          id: 37,
          title: "Rosa hat jetzt ein Furby",
          images: [
            {
              image:
                "/photos/37/8a8c1275-0b4a-49c0-83e1-b9d988855c83.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/37/8a8c1275-0b4a-49c0-83e1-b9d988855c83.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200418",
      entries: [
        {
          id: 41,
          title: "Weltraumübung in der Eifel",
          images: [
            {
              image:
                "/photos/41/16fc3555-2efd-41dd-a0ae-026a14fada5c.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/41/16fc3555-2efd-41dd-a0ae-026a14fada5c.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201128",
      entries: [
        {
          id: 143,
          title: "Ginkgo im Lockdown",
          images: [
            {
              image:
                "/photos/143/79cadca0-0ee3-4e26-9f2a-31d3355bea5f.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/143/79cadca0-0ee3-4e26-9f2a-31d3355bea5f.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201118",
      entries: [
        {
          id: 141,
          title: "Stolen Election",
          images: [
            {
              image:
                "/photos/141/6fb3b7ef-4115-443a-8a9c-9d33359863b8.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/141/6fb3b7ef-4115-443a-8a9c-9d33359863b8.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200406",
      entries: [
        {
          id: 42,
          title: "Winkekatze",
          images: [
            {
              image:
                "/photos/42/1aee67db-449a-4fdb-8bad-eca64ec7a467.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/42/1aee67db-449a-4fdb-8bad-eca64ec7a467.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201016",
      entries: [
        {
          id: 132,
          title: "Anne geht",
          images: [
            {
              image:
                "/photos/132/38169eb1-1d7f-4278-998e-55596c74c789.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/132/38169eb1-1d7f-4278-998e-55596c74c789.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200805",
      entries: [
        {
          id: 99,
          title: "Schau, Gabor, ein neuer Tag!",
          images: [
            {
              image:
                "/photos/99/945f6742-d529-44e8-821c-bfc54eb83b57.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/99/945f6742-d529-44e8-821c-bfc54eb83b57.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201201",
      entries: [
        {
          id: 144,
          title: "Stadtparksee",
          images: [
            {
              image:
                "/photos/144/61151dad-9dbf-48d4-a9d7-2c38bb434997.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/144/61151dad-9dbf-48d4-a9d7-2c38bb434997.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200808",
      entries: [
        {
          id: 100,
          title: "Guck dir die Soße hinter Jupiter an!",
          images: [
            {
              image:
                "/photos/100/1c0313d8-4dc4-494b-ade8-41e7ef989ccf.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/100/1c0313d8-4dc4-494b-ade8-41e7ef989ccf.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200224",
      entries: [
        {
          id: 19,
          title: "Markus ist auf Island",
          images: [
            {
              image:
                "/photos/19/ceea54d5-247e-4203-a79c-f017cbcbd91b.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/19/ceea54d5-247e-4203-a79c-f017cbcbd91b.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200529",
      entries: [
        {
          id: 74,
          title: "Mond über Knaußens Haus",
          images: [
            {
              image:
                "/photos/74/b41edc22-6e4b-4c77-bbde-f18ddad9561a.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/74/b41edc22-6e4b-4c77-bbde-f18ddad9561a.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200907",
      entries: [
        {
          id: 110,
          title: "Anke ist noch im Allgäu",
          images: [
            {
              image:
                "/photos/110/dc73b547-7eff-4c49-8e8b-1826b4747aae.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/110/dc73b547-7eff-4c49-8e8b-1826b4747aae.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200107",
      entries: [
        {
          id: 39,
          title: "Ich beim Helikoptern",
          images: [
            {
              image:
                "/photos/39/56fac8e9-31d3-4ead-b689-8321a6865dbc.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/39/56fac8e9-31d3-4ead-b689-8321a6865dbc.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200613",
      entries: [
        {
          id: 89,
          title: "Verzeih mir, Konrad!",
          images: [
            {
              image:
                "/photos/89/71d31398-a424-4131-ad61-8b8d406e87dc.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/89/71d31398-a424-4131-ad61-8b8d406e87dc.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200619",
      entries: [
        {
          id: 46,
          title: "Da hilft nur Beten!",
          images: [
            {
              image:
                "/photos/46/ddc26c42-2b8d-45ea-b176-cc9bdcfad6ca.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/46/ddc26c42-2b8d-45ea-b176-cc9bdcfad6ca.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201025",
      entries: [
        {
          id: 134,
          title:
            "Maik spiegelt sich in Lacktür aus dem 18. Jhd. in Obermusbach",
          images: [
            {
              image:
                "/photos/134/8e78fe9e-03a6-4ad2-85ae-62ba22a385af.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/134/8e78fe9e-03a6-4ad2-85ae-62ba22a385af.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200712",
      entries: [
        {
          id: 96,
          title: "Steffi umarmt immer noch Bäume - zum Glück",
          images: [
            {
              image:
                "/photos/96/ab6a32fe-68d8-42b7-a755-8b5b3180c478.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/96/ab6a32fe-68d8-42b7-a755-8b5b3180c478.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200218",
      entries: [
        {
          id: 82,
          title:
            "Heuschrecken in Ostafrika bedrohen die Ernte - zu müde zum Grundieren",
          images: [
            {
              image:
                "/photos/82/02648177-f94a-4a33-98eb-73b45a856544.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/82/02648177-f94a-4a33-98eb-73b45a856544.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200331",
      entries: [
        {
          id: 55,
          title: "Jennys Waschmaschine sieht aus wie eine Aztekenmaske",
          images: [
            {
              image:
                "/photos/55/25bc0cb8-6923-47d5-a5a7-f0d1ffd73d62.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/55/25bc0cb8-6923-47d5-a5a7-f0d1ffd73d62.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200221",
      entries: [
        {
          id: 31,
          title: "Rauchgeist überm Leo-Center",
          images: [
            {
              image:
                "/photos/31/4b3f4f79-7867-4598-8053-7c09eade6cd0.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/31/4b3f4f79-7867-4598-8053-7c09eade6cd0.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200602",
      entries: [
        {
          id: 26,
          title: "Protest II",
          images: [
            {
              image:
                "/photos/26/7d1d7fe7-8d38-471f-83ff-95a35a42e319.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/26/7d1d7fe7-8d38-471f-83ff-95a35a42e319.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200702",
      entries: [
        {
          id: 91,
          title: "Schneeeulenküken Hedwig",
          images: [
            {
              image:
                "/photos/91/18d7ae3e-6cb4-4808-ab4c-82064dec26fe.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/91/18d7ae3e-6cb4-4808-ab4c-82064dec26fe.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200306",
      entries: [
        {
          id: 33,
          title: "Sonne",
          images: [
            {
              image:
                "/photos/33/06968ac4-06ef-439f-8456-e23f6ab0c19b.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/33/06968ac4-06ef-439f-8456-e23f6ab0c19b.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200707",
      entries: [
        {
          id: 76,
          title: "Maiks Geldbeutel ist mal wieder weg",
          images: [
            {
              image:
                "/photos/76/75b93d25-8d1a-4348-bb1b-248cbb876ac3.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/76/75b93d25-8d1a-4348-bb1b-248cbb876ac3.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200320",
      entries: [
        {
          id: 56,
          title: "Anton frühstückt Frosties",
          images: [
            {
              image:
                "/photos/56/fed1bdad-dadc-4cef-bfad-e398d9b19d7b.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/56/fed1bdad-dadc-4cef-bfad-e398d9b19d7b.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200720",
      entries: [
        {
          id: 95,
          title: "Das Tattoo des Tages - Hommage an Silke S.",
          images: [
            {
              image:
                "/photos/95/85674d68-f61b-4705-8cac-aa71d1d954d9.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/95/85674d68-f61b-4705-8cac-aa71d1d954d9.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200407",
      entries: [
        {
          id: 58,
          title: "Silkes Schulter nach der OP",
          images: [
            {
              image:
                "/photos/58/3d056753-7dff-4b6e-b95b-bd8bd7b4f759.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/58/3d056753-7dff-4b6e-b95b-bd8bd7b4f759.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200510",
      entries: [
        {
          id: 67,
          title: "Stromkasten in Tübingen",
          images: [
            {
              image:
                "/photos/67/647fdb9a-19d2-478d-9560-1616328d77ac.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/67/647fdb9a-19d2-478d-9560-1616328d77ac.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200829",
      entries: [
        {
          id: 106,
          title: "Eva und Adele in Binz - Inneres Lächeln geht auch mit Maske",
          images: [
            {
              image:
                "/photos/106/57505a7f-2707-4062-bf89-c42266f9841e.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/106/57505a7f-2707-4062-bf89-c42266f9841e.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200210",
      entries: [
        {
          id: 15,
          title: "Das Schnabeltier ist vom Aussterben bedroht",
          images: [
            {
              image:
                "/photos/15/5f2fd077-656c-45fc-a162-3f115b087059.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/15/5f2fd077-656c-45fc-a162-3f115b087059.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201115",
      entries: [
        {
          id: 140,
          title: "Garagen im Ramtel - was fliegt da?",
          images: [
            {
              image:
                "/photos/140/3aa43873-4203-4d86-8904-57430179051b.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/140/3aa43873-4203-4d86-8904-57430179051b.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200410",
      entries: [
        {
          id: 59,
          title: "Karfreitag ist dieses Jahr auch",
          images: [
            {
              image:
                "/photos/59/7fdffa14-5b36-45c1-acc0-e87568e6a418.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/59/7fdffa14-5b36-45c1-acc0-e87568e6a418.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201013",
      entries: [
        {
          id: 151,
          title:
            "Für Matthias Gronemeyer: zwei Künstler mittleren Alters tauschen sich über ihre Wehwehchen aus",
          images: [
            {
              image:
                "/photos/151/ed6ab688-8981-41dd-863f-86e87ed46edb.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/151/ed6ab688-8981-41dd-863f-86e87ed46edb.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200123",
      entries: [
        {
          id: 30,
          title: "Der Schornsteinfeger ist da",
          images: [
            {
              image:
                "/photos/30/8e2f3c58-c3c5-4e9d-bae0-48e6a4382d29.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/30/8e2f3c58-c3c5-4e9d-bae0-48e6a4382d29.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200324",
      entries: [
        {
          id: 52,
          title: "Isolation",
          images: [
            {
              image:
                "/photos/52/fd7309fc-7507-4cb6-8ada-aa9f13a2801d.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/52/fd7309fc-7507-4cb6-8ada-aa9f13a2801d.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200512",
      entries: [
        {
          id: 68,
          title:
            "Schau mal Mama, das stand in der Werkstatt und riecht komisch!",
          images: [
            {
              image:
                "/photos/68/e55cd636-8c16-4b6d-a2a1-07b9fd52a998.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/68/e55cd636-8c16-4b6d-a2a1-07b9fd52a998.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200322",
      entries: [
        {
          id: 130,
          title: "Die Pest - tote Ratte in der Kunstbibliothek",
          images: [
            {
              image:
                "/photos/130/6ce2bde1-5cca-4bba-81d5-59654ddef42f.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/130/6ce2bde1-5cca-4bba-81d5-59654ddef42f.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200308",
      entries: [
        {
          id: 8,
          title: "Dampfsperre nach Brand im Lehrerzimmer",
          images: [
            {
              image:
                "/photos/8/277ac84e-0154-4bfa-84ec-7552a26e6f22.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/8/277ac84e-0154-4bfa-84ec-7552a26e6f22.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200312",
      entries: [
        {
          id: 51,
          title: "Die Blase",
          images: [
            {
              image:
                "/photos/51/56e8f230-8021-482e-b171-f141ef85b128.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/51/56e8f230-8021-482e-b171-f141ef85b128.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200214",
      entries: [
        {
          id: 16,
          title: "Der Gugu",
          images: [
            {
              image:
                "/photos/16/1c4669a6-3d59-4432-bc0a-7b366aa2a3f9.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/16/1c4669a6-3d59-4432-bc0a-7b366aa2a3f9.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201204",
      entries: [
        {
          id: 145,
          title: "Antons heimliches Quarantänezelt",
          images: [
            {
              image:
                "/photos/145/f4b11844-483e-46f8-8b24-22df9b5c2ba2.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/145/f4b11844-483e-46f8-8b24-22df9b5c2ba2.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200504",
      entries: [
        {
          id: 66,
          title: "Den Wels im Stadtparksee gesehen",
          images: [
            {
              image:
                "/photos/66/eefc0f2c-22b6-420b-a6a1-72f868610b24.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/66/eefc0f2c-22b6-420b-a6a1-72f868610b24.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200930",
      entries: [
        {
          id: 127,
          title: "Milchglasscheibe",
          images: [
            {
              image:
                "/photos/127/c0050578-c23d-4fd1-a812-735a6fbb4938.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/127/c0050578-c23d-4fd1-a812-735a6fbb4938.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200310",
      entries: [
        {
          id: 9,
          title: "Blick nach oben in Ditzingen",
          images: [
            {
              image:
                "/photos/9/59124d25-0447-4d30-b6bb-b60700c48733.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/9/59124d25-0447-4d30-b6bb-b60700c48733.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201004",
      entries: [
        {
          id: 120,
          title: "Regentropfen im Scheinwerferlicht auf Dachfenster",
          images: [
            {
              image:
                "/photos/120/b2db2324-66db-4692-99bf-0970920a7c57.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/120/b2db2324-66db-4692-99bf-0970920a7c57.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200819",
      entries: [
        {
          id: 105,
          title: "Der Tyranno bewegt sich",
          images: [
            {
              image:
                "/photos/105/5183dfec-302d-428f-afd0-e8238c4a4cc3.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/105/5183dfec-302d-428f-afd0-e8238c4a4cc3.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200604",
      entries: [
        {
          id: 87,
          title:
            "#BlackLivesMatter-Proteste nach dem gewaltsamen Tod von George Floyd durch einen weißen Polizisten",
          images: [
            {
              image:
                "/photos/87/8310c8d2-a102-4172-833b-724e47d5a2a2.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/87/8310c8d2-a102-4172-833b-724e47d5a2a2.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200824",
      entries: [
        {
          id: 124,
          title: "Ava am Strand mit Handtuch",
          images: [
            {
              image:
                "/photos/124/fbe0bc30-863b-41d8-b0c3-c0f27be3f2b5.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/124/fbe0bc30-863b-41d8-b0c3-c0f27be3f2b5.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200804",
      entries: [
        {
          id: 98,
          title: "Dein Hund sieht ja aus wie Ohnezahn!",
          images: [
            {
              image:
                "/photos/98/bf1404bd-91ba-47c5-a65d-6b7b81ad9142.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/98/bf1404bd-91ba-47c5-a65d-6b7b81ad9142.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200725",
      entries: [
        {
          id: 102,
          title: "Matschsoße mit doppelköpfigem Drachen",
          images: [
            {
              image:
                "/photos/102/8e9bc759-17d8-42aa-9906-fb77e8814e2a.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/102/8e9bc759-17d8-42aa-9906-fb77e8814e2a.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200121",
      entries: [
        {
          id: 81,
          title: "Steinschlag",
          images: [
            {
              image:
                "/photos/81/18828b4a-ae70-41e8-9e15-cca4738ce9e0.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/81/18828b4a-ae70-41e8-9e15-cca4738ce9e0.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200913",
      entries: [
        {
          id: 108,
          title: "Leslie im Galerieverein",
          images: [
            {
              image:
                "/photos/108/10d0963b-ba10-4d63-97c7-4acaaafaf45c.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/108/10d0963b-ba10-4d63-97c7-4acaaafaf45c.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200402",
      entries: [
        {
          id: 40,
          title: "Kinder-Kreide-Vogel vor Alex`Haus",
          images: [
            {
              image:
                "/photos/40/458dacd4-9285-4fc3-a447-d119337a2bb7.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/40/458dacd4-9285-4fc3-a447-d119337a2bb7.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200607",
      entries: [
        {
          id: 77,
          title: "MRT ohne Rosafärber geschafft",
          images: [
            {
              image:
                "/photos/77/a42526c1-f6ac-4e46-a4b6-365af267ac78.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/77/a42526c1-f6ac-4e46-a4b6-365af267ac78.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200422",
      entries: [
        {
          id: 61,
          title:
            "Sehe nix mehr. Nur noch innere Bilder. Kinder leiden unter der Isolation. Nicht lustig!",
          images: [
            {
              image:
                "/photos/61/4cca3902-585b-444b-9cf3-6dad0fe40210.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/61/4cca3902-585b-444b-9cf3-6dad0fe40210.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200416",
      entries: [
        {
          id: 123,
          title: "Anton im Karton",
          images: [
            {
              image:
                "/photos/123/5f5d82d6-52ae-40f5-9dd3-2b847d458ecf.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/123/5f5d82d6-52ae-40f5-9dd3-2b847d458ecf.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200617",
      entries: [
        {
          id: 45,
          title: "Liebeskummer II - unentschieden",
          images: [
            {
              image:
                "/photos/45/a69d2cb4-3271-4e3f-a4be-789e7638e7a4.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/45/a69d2cb4-3271-4e3f-a4be-789e7638e7a4.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200922",
      entries: [
        {
          id: 121,
          title: "Emil im Größenwahn",
          images: [
            {
              image:
                "/photos/121/f1da7a73-63df-4e8e-acc0-b8f7bf099be9.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/121/f1da7a73-63df-4e8e-acc0-b8f7bf099be9.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200301",
      entries: [
        {
          id: 20,
          title: "Stell dir vor, ich wäre auf einem Floß auf dem offenen Meer",
          images: [
            {
              image:
                "/photos/20/e2102a5c-95b0-4158-9482-c73484bfe6a9.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/20/e2102a5c-95b0-4158-9482-c73484bfe6a9.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200215",
      entries: [
        {
          id: 17,
          title: "Der heimische Baummarder",
          images: [
            {
              image:
                "/photos/17/9e09157f-2acd-436b-862c-3824ca214eab.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/17/9e09157f-2acd-436b-862c-3824ca214eab.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201021",
      entries: [
        {
          id: 133,
          title: "Maik verschwommen",
          images: [
            {
              image:
                "/photos/133/92e280e3-4ef9-4972-bd3f-624154f98c1d.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/133/92e280e3-4ef9-4972-bd3f-624154f98c1d.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200509",
      entries: [
        {
          id: 38,
          title: "Mein Schatten",
          images: [
            {
              image:
                "/photos/38/6a258b3a-d985-4f68-8f50-35ba3a230c3e.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/38/6a258b3a-d985-4f68-8f50-35ba3a230c3e.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200601",
      entries: [
        {
          id: 25,
          title: "Protest I",
          images: [
            {
              image:
                "/photos/25/9dea97e5-f6c7-4fca-a467-f90c5ff7c620.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/25/9dea97e5-f6c7-4fca-a467-f90c5ff7c620.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200311",
      entries: [
        {
          id: 50,
          title: "Eine Mutter",
          images: [
            {
              image:
                "/photos/50/2e7e709a-4d8e-4e7f-bfd6-8b0cc6a4d441.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/50/2e7e709a-4d8e-4e7f-bfd6-8b0cc6a4d441.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200816",
      entries: [
        {
          id: 101,
          title: "Schöne Dunkelheit auf Rügen",
          images: [
            {
              image:
                "/photos/101/75f16f34-1664-46f2-975e-a066035e0463.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/101/75f16f34-1664-46f2-975e-a066035e0463.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200424",
      entries: [
        {
          id: 62,
          title: "Maik chillt",
          images: [
            {
              image:
                "/photos/62/f2cb6aa9-9470-4c68-b1ad-594e62718ac4.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/62/f2cb6aa9-9470-4c68-b1ad-594e62718ac4.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200522",
      entries: [
        {
          id: 70,
          title: "Zunge passend zu Turnschuhen",
          images: [
            {
              image:
                "/photos/70/01925683-0ecf-4b93-af86-7676bbedc227.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/70/01925683-0ecf-4b93-af86-7676bbedc227.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200518",
      entries: [
        {
          id: 24,
          title: "Blatt vorm Mund",
          images: [
            {
              image:
                "/photos/24/b6cda26c-ffd2-4d2b-8d4e-8fa95a82c1ac.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/24/b6cda26c-ffd2-4d2b-8d4e-8fa95a82c1ac.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200328",
      entries: [
        {
          id: 116,
          title: "So könnte ich Oma und Opa doch besuchen",
          images: [
            {
              image:
                "/photos/116/8bea86bb-fcb5-4067-9d51-81b1f9f884ba.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/116/8bea86bb-fcb5-4067-9d51-81b1f9f884ba.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201005",
      entries: [
        {
          id: 129,
          title: "Unterhose",
          images: [
            {
              image:
                "/photos/129/a41e5daa-795d-49db-bba3-284b428e4469.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/129/a41e5daa-795d-49db-bba3-284b428e4469.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200516",
      entries: [
        {
          id: 69,
          title: "Spinnerinkreuz Zavelstein",
          images: [
            {
              image:
                "/photos/69/63234d12-3c20-4024-a277-71641ddcf6b7.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/69/63234d12-3c20-4024-a277-71641ddcf6b7.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20201105",
      entries: [
        {
          id: 137,
          title: "Leo-Center Sunset",
          images: [
            {
              image:
                "/photos/137/9af15e09-66df-422d-952e-a8e40b4f31c6.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/137/9af15e09-66df-422d-952e-a8e40b4f31c6.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200202",
      entries: [
        {
          id: 11,
          title: "Schatten der Eibe auf dem Kleiderschrank",
          images: [
            {
              image:
                "/photos/11/1ff8132d-d6b5-4ae4-854e-ab059016c51d.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/11/1ff8132d-d6b5-4ae4-854e-ab059016c51d.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200330",
      entries: [
        {
          id: 34,
          title: "Ava mit offenen Knien",
          images: [
            {
              image:
                "/photos/34/0dd2b958-2f36-43b3-9c37-8ac74ed9f8be.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/34/0dd2b958-2f36-43b3-9c37-8ac74ed9f8be.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200208",
      entries: [
        {
          id: 14,
          title: "Brille vergessen",
          images: [
            {
              image:
                "/photos/14/f81bc47a-f4bd-4976-88d1-ddfe23d1d35c.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/14/f81bc47a-f4bd-4976-88d1-ddfe23d1d35c.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
    {
      day: "20200722",
      entries: [
        {
          id: 122,
          title: "Und immer nur dieses scheiß Tagebuch",
          images: [
            {
              image:
                "/photos/122/620ab399-399a-4719-a531-2ec8cfeed170.jpg.1800x2400_q70_crop.jpg",
              caption: "",
              thumbnail:
                "/photos/122/620ab399-399a-4719-a531-2ec8cfeed170.jpg.300x400_q70_crop.jpg",
            },
          ],
        },
      ],
    },
  ],
};

const initialState = {
  days: {},
  daysWithEntries: {},
  dayDetail: {},
  loading: false,
  startDayIndex: 1,
};

const days = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // ---------------------------------------------- GET DAYS
      case CREATE_DAYS: {
        const stateDraft = draft;
        const daysList = data.results;
        daysList.forEach((day) => {
          const entries = {};
          day.entries.forEach((memory) => {
            entries[memory.id] = memory;
          });
          stateDraft.days[day.day] = {
            date: day.day,
            type: "date",
            entries,
          };
          stateDraft.daysWithEntries[day.day] = {
            date: day.day,
            type: "date",
            entries,
          };
        });
        stateDraft.loading = false;
        break;
      }

      // ---------------------------------------------- CREATE DUMMY DAYS
      case CREATE_DUMMY_DAYS: {
        const stateDraft = draft;
        const startDate = dayjs(action.payload).toDate();
        const endDate = new Date("2021-01-03T03:24:00");
        const dayOfWeek = dayjs(startDate, "YYYYMMDD").day();
        const startDateNumber = dayOfWeek - 1;
        stateDraft.startDayIndex = startDateNumber;
        if (dayOfWeek === 0) {
          for (let i = -6; i < 0; i += 1) {
            const newDate = dayjs(startDate, "YYYYMMDD")
              .day(i)
              .format("YYYYMMDD");
            stateDraft.days[newDate] = {
              date: newDate,
              type: "placeholder",
              entries: {},
            };
          }
        } else {
          for (let i = 0; i < startDateNumber; i += 1) {
            const newDate = dayjs(startDate, "YYYYMMDD")
              .day(i)
              .format("YYYYMMDD");
            stateDraft.days[newDate] = {
              date: newDate,
              type: "placeholder",
              entries: {},
            };
          }
        }

        while (startDate <= endDate) {
          const day = dayjs(new Date(startDate)).format("YYYYMMDD");
          stateDraft.days[day] = {
            date: day,
            type: "date",
            entries: {},
          };
          startDate.setDate(startDate.getDate() + 1);
        }
        stateDraft.loading = false;
        break;
      }
      default:
        return state;
    }
  });

export default days;
