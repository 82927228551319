import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWindowSize } from "react-use";

import { daysSelector } from "../../selectors/days";
import { getCalendarDetail } from "../../actions/calendars";
import { createDummyDays, getDays } from "../../actions/days";

import styles from "./calendar.scss";

import Calendar from "../../components/calendar";

const CalendarView = (props) => {
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();
  const calendarDetail = {
    id: "249d38e2-0183-4598-90e4-f4d65fedea93",
    start_date: "20200102",
    end_date: "20201231",
  };
  const days = daysSelector(useSelector((state) => state.days.days));
  const startDayIndex = useSelector((state) => state.days.startDayIndex);
  useEffect(() => {
    dispatch(createDummyDays(calendarDetail.start_date));
    dispatch(getDays());
  }, []);

  return (
    <div className={styles.container}>
      <Calendar
        days={days}
        width={width}
        height={height}
        startDayIndex={startDayIndex}
      />
    </div>
  );
};

CalendarView.propTypes = {};

export default CalendarView;
